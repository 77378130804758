import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

export const Recommend = () => {

  const { t, i18n } = useTranslation();

  return (
    <div className="recommend">
      <div className="div">
        
        <div className="overlap">
          <img
            className="mask-group"
            alt="Mask group"
            src="/img/zhong.jpg"
          />
          <div className="text-wrapper-2">{t("h1_title")}</div>
          <div className="text-wrapper-3" style={t('is_en')==1?{whiteSpace:'wrap',width:'630px',wordBreak:'breakAll',fontSize:"26px"}:{}}>{t("h1_address")}</div>
          <div className="element-px">
            <div className="overlap-2">
              <div className="subtract-wrapper">
                <img
                  className="subtract"
                  alt="Subtract"
                  src="/img/img2/subtract.svg"
                />
              </div>
              <div className="ellipse" />
            </div>
          </div>
          <div className="group-2">
            <div className="text-wrapper-4">{t("h_phone_title")}</div>
            <div className="group-3">
              <div className="text-wrapper-5"><a href={"tel:" + t("h1_phone_number")}>{t("h1_phone_number")}</a></div>
              <div className="vector-wrapper">
                <img
                  className="vector"
                  alt="Vector"
                  src="/img/img2/vector-264-1.svg"
                />
              </div>
            </div>


          </div>

          <div className="group-2" style={{"top":1256}}>
            <div className="text-wrapper-4">{t("h_link_title")}</div>
            <div className="group-3">
              <div className="text-wrapper-5" style={{"left":"6px"}}><a href={t("h1_link_url")}>{t("h1_link_url")}</a></div>

            </div>


          </div>

          <img
            className="image"
            alt="Image"
            src="/img/img2/---.svg"
            style={t("is_en")=='1'?{top:" 820px"}:{}}
          />
          <div className="group-5">
            <div className="text-wrapper-4">{t("h_price_title")}</div>
            <div className="text-wrapper-6" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h_price_1_title")}</div>
            <div className="text-wrapper-7" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h1_price_1_price")}</div>
            <div className="text-wrapper-8" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h1_price_2_price")}</div>
            <div className="text-wrapper-9" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h_price_2_title")}</div>
          </div>
        </div>

        <div className="overlap">
          <img
              className="mask-group"
              alt="Mask group"
              src="/img/jiu.jpg"
          />
          <div className="text-wrapper-2">{t("h2_title")}</div>
          <div className="text-wrapper-3" style={t('is_en')==1?{whiteSpace:'wrap',width:'630px',wordBreak:'breakAll',fontSize:"26px"}:{}}>{t("h2_address")}</div>
          <div className="element-px">
            <div className="overlap-2">
              <div className="subtract-wrapper">
                <img
                    className="subtract"
                    alt="Subtract"
                    src="/img/img2/subtract.svg"
                />
              </div>
              <div className="ellipse" />
            </div>
          </div>
          <div className="group-2">
            <div className="text-wrapper-4">{t("h_phone_title")}</div>
            <div className="group-3">
              <div className="text-wrapper-5" ><a href={"tel:" + t("h2_phone_number")}>{t("h2_phone_number")}</a></div>
              <div className="vector-wrapper">
                <img
                    className="vector"
                    alt="Vector"
                    src="/img/img2/vector-264-1.svg"
                />
              </div>
            </div>

          </div>

          <div className="group-2" style={{"top":1256}}>
            <div className="text-wrapper-4">{t("h_link_title")}</div>
            <div className="group-3">
              <div className="text-wrapper-5" style={{"left":"6px"}}><a href={t("h2_link_url")}>{t("h2_link_url")}</a></div>

            </div>


          </div>

          <img
              className="image"
              alt="Image"
              src="/img/img2/---.svg"
              style={t("is_en")=='1'?{top:" 820px"}:{}}
          />
          <div className="group-5">
            <div className="text-wrapper-4">{t("h_price_title")}</div>
            <div className="text-wrapper-6" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h_price_1_title")}</div>
            <div className="text-wrapper-7" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h2_price_1_price")}</div>
            <div className="text-wrapper-8" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h2_price_2_price")}</div>
            <div className="text-wrapper-9" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h_price_2_title")}</div>
          </div>
        </div>


        <div className="overlap">
          <img
              className="mask-group"
              alt="Mask group"
              src="/img/chang2.jpg"
          />
          <div className="text-wrapper-2">{t("h3_title")}</div>
          <div className="text-wrapper-3" style={t('is_en')==1?{whiteSpace:'wrap',width:'630px',wordBreak:'breakAll',fontSize:"26px"}:{}}>{t("h3_address")}</div>
          <div className="element-px">
            <div className="overlap-2">
              <div className="subtract-wrapper">
                <img
                    className="subtract"
                    alt="Subtract"
                    src="/img/img2/subtract.svg"
                />
              </div>
              <div className="ellipse" />
            </div>
          </div>
          <div className="group-2">
            <div className="text-wrapper-4">{t("h_phone_title")}</div>
            <div className="group-3">
              <div className="text-wrapper-5"><a href={"tel:" + t("h3_phone_number")}>{t("h3_phone_number")}</a></div>
              <div className="vector-wrapper">
                <img
                    className="vector"
                    alt="Vector"
                    src="/img/img2/vector-264-1.svg"
                />
              </div>
            </div>

          </div>

          <div className="group-2" style={{"top":1256}}>
            <div className="text-wrapper-4">{t("h_link_title")}</div>
            <div className="group-3">
              <div className="text-wrapper-5" style={{"left":"6px"}}><a href={t("h3_link_url")}>{t("h3_link_url")}</a></div>

            </div>


          </div>

          <img
              className="image"
              alt="Image"
              src="/img/img2/---.svg"
              style={t("is_en")=='1'?{top:" 820px"}:{}}
          />
          <div className="group-5">
            <div className="text-wrapper-4">{t("h_price_title")}</div>
            <div className="text-wrapper-6" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h_price_1_title")}</div>
            <div className="text-wrapper-7" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h3_price_1_price")}</div>
            <div className="text-wrapper-8" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h3_price_2_price")}</div>
            <div className="text-wrapper-9" style={t("is_en")=='1'?{fontSize:'25px'}:{}}>{t("h_price_2_title")}</div>
          </div>
        </div>

        <div className="overlap-bottom-text">
          <div className="text-wrapper-10">{t("h_tips")}</div>
          <div className="text-wrapper-11">
            {t("h_tips_1")}
            <br />
            {t("h_tips_2")}
            <br />
            {t("h_tips_3")}
            <br />
            {t("h_tips_4")}
          </div>
        </div>




      </div>
    </div>
  );
};


export default Recommend;
