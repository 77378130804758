import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const pageApi = createApi({
    reducerPath: 'pageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://h5.aiweber.cn/lixiang/v2'
    }),
    endpoints(build) {
        return {
            
        }
    }
})

export const {
    useSmsMutation
} = pageApi

export default pageApi