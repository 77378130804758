import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Application from '../page/application';
import Information from '../page/information';
import Introduce from '../page/introduce';
import Moment from '../page/moment';
import Recommend from '../page/recommend';
import Login from '../page/login';
import Home from '../page/Home';

const Routes = () => {
    const element = useRoutes([
        
        {
            path: 'login',
            element: <Login />,
        },
        
        {
            path: 'home',
            element: <Home />,
            children:[
                {
                    path: '/home/introduce',
                    element: <Introduce />,
                },
                {
                    path: '/home/application',
                    element: <Application />,
                },
                {
                    path: '/home/information',
                    element: <Information />,
                },
                {
                    path: '/home/moment',
                    element: <Moment />,
                },
                {
                    path: '/home/recommend',
                    element: <Recommend />,
                },
                {
                    path: '/home',
                    element: <Navigate to='/home/introduce' replace/>,
                },
            ]
        },
        {
            path: '/',
            element: <Navigate to='/login' replace/>,
        },
    ])
    return element
}

export default Routes;
