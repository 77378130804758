import React from 'react';
import Routes from '../../router';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Login from '../login';
import { NavLink } from 'react-router-dom';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { changeXuan } from '../../store/pageSlice';
const Home = () => {
    const [isLogin, setIsLogin] = useState(false)
    // const [xuan, setxuan] = useState(1)
    const nav=useNavigate()
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {pageSlice} = useSelector(state => state);
    const funcLogin = () => {
        setIsLogin(true)
    }

    const loginItem = () => {
        return (
            <>
                <Outlet />
                
                <div className='home'>

                    <div className="overlap-wrapper">
                        <div className="overlap">
                            <NavLink to='/home/recommend' replace onClick={() => { dispatch(changeXuan(4))
                                 }}>
                                <div className="overlap-2">
                                    <img
                                        className="img-4"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 4 ? "/img/t1.svg" : "/img/t0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'13px'}:{}}>{t("nav_4")}</div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/application' replace onClick={() => { dispatch(changeXuan(3)) }}>
                                <div className="overlap-3">
                                    <img
                                        className="img-3"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 3 ? "/img/b1.svg" : "/img/b0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'-12px'}:{}}>{t("nav_3")}</div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/introduce' replace onClick={() => {dispatch(changeXuan(1)) }}>
                                <div className="overlap-4">
                                    <img
                                        className="img-1"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 1 ? "/img/j1.svg" : "/img/j0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2">{t("nav_1")}</div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/information' replace onClick={() => { dispatch(changeXuan(2)) }}>
                                <div className="overlap-5">
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'-8px'}:{}}>{t("nav_2")}</div>
                                    <div className="view-2">
                                        <img
                                            className="img-2"
                                            alt="Element"
                                            src={
                                                pageSlice.xuan === 2 ? "/img/x1.svg" : "/img/x0.svg"
                                            }
                                        />
                                    </div>
                                </div>
                            </NavLink>
                
                            <a href='https://as.alltuu.com/album/1224202063/?from=link&menu=live' >
                                <div className="overlap-7">
                                    <div className="view-2">
                                        <img
                                            className="img-5"
                                            alt="Element"
                                            src={
                                                pageSlice.xuan === 5 ? "/img/s1.svg" : "/img/s0.svg"
                                            }
                                        />
                                    </div>
                                    <div className="text-wrapper-2" style={t('is_en')==1?{left:'10px'}:{}}>{t("nav_5")}</div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </>

        )
    }


    return (
        <div>
            
            {
                loginItem()
            }

        </div>
    );
}

export default Home;
