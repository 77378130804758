
import './App.css';
import Routes from './router';
import Home from './page/Home';

function App() {
  return (
    <div className="App">
      {/* <Home/> */}
      <Routes/>

    </div>
  );
}

export default App;
