import React from "react";

import "./style.css";
import { useTranslation } from "react-i18next";

const Introduce = () => {
  const { t, i18n } = useTranslation();

  const zhItem = () => {
    return (
      <>
        <div className="text-wrapper-4">尊敬的理想汽车合作伙伴：</div>
        <div className="text-wrapper-5">
          理想汽车2023全球合作伙伴大会将于2023年10月12日在江苏常州举行。
          在此，我们向您发出诚挚的邀请，共同见证理想汽车飞速成长的八年，同行理想，共赴每一次飞跃。

          <br />
          <br />
          过去八年，我们聚焦中国家庭用户市场需求，不断进阶，坚持打造最卓越的产品与服务。2022年以来，我们陆续推出了理想L系列的三款产品，在所有合作伙伴的支持下，理想汽车以惊人的速度突破了每月1万辆、2万辆和3万辆的交付量里程碑，在多个细分市场都取得了领先的销量成绩，成为国内首家最快达成40万辆交付的新势力车企。这份荣誉属于每一个与理想汽车同行的合作伙伴，属于每一个与理想汽车同行的家庭。
          <br />
          <br />
          作为家庭用户市场的先行者，2023年到2025年将是理想汽车从1-10阶段最重要的三年。在新阶段，我们会面临更多考验，我们相信在所有合作伙伴的共同支持与努力下，我们将不断突破，实现更高的目标，携手共创理想。

          <br />
          <br />
          <div style={{ textAlign: "right" }} className="ming">
            理想汽车
            <br />
            2023年10月12日
          </div>
        </div>

      </>
    )
  }

  const enItem = () => {
    return (
      <>
        <div className="text-wrapper-4">Dear Distinguished Partner, </div>
        <div className="text-wrapper-5">

          Li Auto Global Partners Conference 2023 will be held in Changzhou, Jiangsu province on Oct 12, 2023. We sincerely invite you to join the conference and witness the rapid growth that Li Auto has achieved over the past eight years and embark on every leap together. Please join us as we come together to advance your dreams  with Li Auto.
          <br />
          <br />
          Over the past eight years, we have focused on the demands of the Chinese family user market and continuously evolved, persistently striving to create the most excellent products and services. Since 2022, we have successively launched three L-series products. With the support of all our partners, Li Auto has achieved remarkable milestones in monthly deliveries, surpassing 10,000, 20,000, and 30,000 units. We have achieved leading sales performance in multiple segmented markets and become the first domestic New Force brand company to reach 400,000 deliveries. This honor belongs to every partner who walks side by side with Li Auto as well as every family who accompanies us.

          <br />
          <br />

          As a pionner in the family user market, it will be the most important period from 2023 to 2025 as Li Auto will move from stage 1 to 10. In this new stage, we will face more challenges. However, we believe that with the joint support and efforts of all our partners, we will continue to break through and achieve higher goals, collaborating to create ideals together.
          <br />
          <br />
          <div style={{ textAlign: "right" }} className="ming2">
            Li Auto

            <br />
            12th Oct 2023
          </div>

        </div>

      </>
    )
  }
  return (
    <div className="introduce">
      <div className="div" >

        {/* <button onClick={() => { localStorage.removeItem('li_token')
        localStorage.removeItem('li_user_id') }} style={{ position: 'absolute' }}>登出</button> */}
        <img
          className="mask-group"
          alt="Mask group"
          src="/img/j-bg2.png"
        />
        <img src={t("is_en") == 1 ? "/img/logo-en.png" : "/img/login/logo.png"} alt="" className="logo" />
        <div className="text-wrapper-3">{t("j_title")}</div>
        {
          t("is_en") === '1' ? enItem() : zhItem()
        }

      </div>
    </div>
  );
};

export default Introduce
