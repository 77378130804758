import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
export const Moment = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="moment">
      <div className="div">
        
        <div className="group">
          <div className="home-indicator-light">
            <div className="home-indicator" />
          </div>
        </div>
        <div className="text-wrapper-2">{t("p_more")}</div>
        <img
          className="img"
          alt="Img"
          src="/img/img2/-------@3x.png"
        />

      </div>
    </div>
  );
};


export default Moment;
